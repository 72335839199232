import axios from "axios";
import { useQueryClient } from "react-query";
import { get } from "react-scroll/modules/mixins/scroller";
import { errorServer } from '../utilities/messages/messages';

//export const domain_server = process.env.REACT_APP_API || "http://localhost:8000";
// export const domain_server = process.env.REACT_APP_API || "https://sisboa.net";
export const domain_server = process.env.REACT_APP_API || "https://ic-continental.sisboa.net/ic-pedidos-backend/public/";

const token_link = "api/auth/Users/login";
const createNew_link = "api/auth/Users/register";
const getMe_link = "api/auth/me";
const activateAccount_link = "api/auth/activate-account";

const validateCodeRequestChangePassword_link = "api/auth/Users/validate-code-request-changepassword";
const updatePassword_link = "api/auth/Users/change-user-password";

// GETTING TOKEN
export const getUserToken = async (post) => {
  // return axios.post(`${domain_server}${token_link}`, post).then(({ data }) => data);
};
export const createNewUser = async (post) => {
  // return axios.post(`${domain_server}${createNew_link}`, post).then(({ data }) => data);
};
export const getTokenValidationAccount = (post) => {
  return axios.post(`${domain_server}${activateAccount_link}`, post).then(({ data }) => data);
};
export const getMeWithToken = (post) => {
  return axios.post(`${domain_server}${getMe_link}`, post).then(({ data }) => data);
};


export const validateCodeRequestChangePassword = (post) => {
  return axios.post(`${domain_server}${validateCodeRequestChangePassword_link}`, post).then(({ data }) => data);
};
export const updatePassword = (post) => {
  return axios.post(`${domain_server}${updatePassword_link}`, post).then(({ data }) => data);
};

export const logOut = () => {
  let AuthUserToken = {logged: false};
  {localStorage.setItem('AuthUserToken', JSON.stringify(AuthUserToken))}
  console.log('SUCCESS LOGOUT');
  window.location.reload();
};

// no usage
/*
export const filterMovie = async (arrayTmp, word) => {
  try {
    return await arrayTmp.filter(movie  => movie.title.toLocaleLowerCase().includes(word)); 
  } catch (error) {
    return []
  }
};
*/

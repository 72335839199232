import React, { Fragment, useEffect, useState } from 'react'
import { CarrucerConFlechas } from '../../IndexPage/CarrucerConFlechas'
import { Footer } from '../Footer/Footer'
import { Header } from '../Header/Header'
import { Register } from '../Login/Register'
import { PrincipalContainer, RegisterContainer, OurPlansContainer, CardPlans, Div, TileText, Texto } from './HomeElements'


//getting from redux
import { useSelector, useDispatch } from 'react-redux'


export const Home = () => {

    const { tokenDataObjet: TokenFromRedux } = useSelector(state => state.auth.tokenDataSlice);

    const [UserListTest, setUserListTest] = useState([
        {
            id: 1,
            name: "Benefício",
            desc: "Laboris nulla deserunt dolore et adipisicing dolore exercitation. 1 Laboris nulla deserunt dolore et adipisicing dolore exercitation. 1"

        },
        {
            id: 2,
            name: "Benefício 2",
            desc: "Laboris nulla deserunt dolore et adipisicing dolore exercitation. 1 Laboris nulla deserunt dolore et adipisicing dolore exercitation. 1"


        }
    ])




    return (
        <>
            {TokenFromRedux.logged ?
                <Header logeado={true} /> : <Header logeado={false} />
            }

            <CarrucerConFlechas />
            <PrincipalContainer >

                {/* 01 column */}
                <OurPlansContainer name="ourPlansContent">

                    <TileText
                        color='#39A6A3'
                        font='46px'
                        marginTop="30px">
                        Planes y Beneficios
                    </TileText>


                    {/* Leyendo cardsView from array api */}
                    {UserListTest.map(nombre => {
                        return <Fragment key={nombre.id}>
                            <Div fondo="#fff" borderRadius="6px">
                                <CardPlans font='26px' color="#00A19D">
                                    <Div color='#fff' font="20px" fondo="#39A6A3"> {nombre.name}</Div>
                                    <TileText>{nombre.desc}</TileText>
                                </CardPlans>
                            </Div>
                        </Fragment>
                    })}
                </OurPlansContainer>






                {/* 02 column */}
                <RegisterContainer name="registerContent">

                    <TileText
                        color='#39A6A3'
                        font='46px'
                        marginTop="30px">
                        Comienza  100% Gratis!
                    </TileText>


                    <Register />

                    <Div marginLeft="200px" marginTop="50px">
                        <Div>
                            <Texto><p>LoreConsectetur irure anim ea dolor consectetur deserunt mollit eu nostrud laboris.</p></Texto>
                        </Div>
                        <Div>
                            <Texto>Pariatur anim exercitation velit minim adipisicing veniam labore. Aute culpa excepteur non Lorem consectetur.
                                Mollit laboris aliqua commodo irure voluptate dolore laborum do deserunt elit irure duis do anim. Officia tempor
                                deserunt aliquip culpa ipsum mollit consectetur veniam duis sit laboris. Consectetur et fugiat sint
                                fugiat cupidatat non est Lorem mollit minim.</Texto>
                        </Div>
                        <Div>
                            <Texto>Pariatur anim exercitation velit minim adipisicing veniam labore. Aute culpa excepteur non Lorem consectetur.
                                Mollit laboris aliqua commodo irure voluptate dolore laborum do deserunt elit irure duis do anim. Officia tempor
                                deserunt aliquip culpa ipsum mollit consectetur veniam duis sit laboris. Consectetur et fugiat sint
                                fugiat cupidatat non est Lorem mollit minim.</Texto>
                        </Div>
                        <Div>
                            <Texto>PROBANDO 002</Texto>
                        </Div>
                        <Div>
                            <Texto>PROBANDO 001</Texto>
                        </Div>
                        <Div>
                            <Texto>PROBANDO 002</Texto>
                        </Div>
                    </Div>

                </RegisterContainer>




                <Footer />
            </PrincipalContainer>



        </>
    )
}


import React, { useState } from 'react'

import { Container, LoginContainer, Texto, ImgContainer, Button } from '../Login/LoginElement';
import imagenLoginAccess from '../../../images/commun-imges/login-access.svg'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'
import { FormikInputsControl } from '../../../utilities/FormikInputsControl';
import { error_MSG, question_MSG, success_MSG, TestDialog } from '../../../utilities/sweetAalerts/dialogs';
import { updatePassword } from '../../../Api/peticiones_server';
import { useNavigate } from 'react-router-dom';


export const FormNewPassword = ({ margin = "", codeToChange = "0" }) => {

    const [MessageFromToken, setMessageFromToken] = useState("")
    const navitage = useNavigate()

    const SendNewPassword2 = async (data) => {

        console.log(data);
    }

    const SendNewPassword = async (data) => {

        let AuthUserToken = {};
        if (data) {
            updatePassword(data).then(data => {
                try {
                    data.success ?
                        <>
                            {
                                success_MSG(data.msg)
                            }
                            {
                                setTimeout(() => {
                                    window.close()
                                }, 3300)
                            }
                        </>
                        :
                        <> </>

                    // ERROR
                    data.msg.error ? setMessageFromToken(data.msg.error)
                        // <>
                        // {console.log('EERORRRRR')}
                        //     {setMessageFromToken(data.msg.error)}
                        //     {AuthUserToken = { token: '', time: 0, logged: false }}
                        //     {localStorage.setItem('AuthUserToken', JSON.stringify(AuthUserToken))}
                        //     {setMessageFromToken("")}

                        //     {/* {error_MSG('Correo o contraseña incorrecto!')} */}
                        // </>
                        :
                        <></>

                    data.msg.info ? error_MSG(data.msg.info, 5000) : <></>
                } catch (error) {

                }

            })
        }
    }

    // Yup validate form:
    const validateYupForm = Yup.object({

        new_password:
            Yup.string().required('La contraseña es requerida')
                .min(3, 'La contraseña debe de tener almenos 3 caracteres')
                .max(25, 'Excede el maximo de caracteres permitido'),

        ConfirmPassword: Yup.string()
            .oneOf([Yup.ref('new_password'), null], 'Las contraseñas no coinciden')
    });

    return (
        <>
            {console.log('HOLA SOY FORMULARIO')}
            <Formik
                initialValues={{
                    new_password: '',
                    ConfirmPassword: '',
                    codeToChange: codeToChange
                }}

                validationSchema={validateYupForm}

                //** FINALIZAR Y ENVIAR
                onSubmit={async (userData) => {
                    SendNewPassword(userData);
                }}
            >

                {formik => (
                    <div >
                        <Form>

                            <Container margin={margin}>

                                <LoginContainer >

                                    <ImgContainer src={imagenLoginAccess} />

                                    <div >
                                        <Texto font="30px" color="#00A19D">  Modificando Contraseña </Texto>
                                        {/* <FormikInputsControl control='select' name='nuevoRegisterCheck'/> */}

                                        {/* <Texto font="25px" color="#00A19D">COLOCA TUS DATOS </Texto> */}
                                        <br /> <br />
                                        <FormikInputsControl control='input' type="password" label='Contraseña' name='new_password' />
                                        <FormikInputsControl control='input' type="password" label='Confirmar Contraseña' name='ConfirmPassword' />
                                        <br />
                                        <Button type="submit" color='#e50914'>Confirmar</Button>
                                        <br />     <br />
                                        {
                                            <Texto font="20px" color="red"> {MessageFromToken}  </Texto>
                                        }
                                        <br />
                                        {/* <Texto font="20px" color="#00A19D"> GoGym  </Texto> <br />
            <Texto font="16px" color="#00A19D"> Push Yours Limits!  </Texto> */}
                                    </div>

                                </LoginContainer>

                            </Container>
                        </Form>
                    </div>
                )}
            </Formik>
        </>
    )
}

// import { IndexPage } from "../IndexPage/IndexPage";
// import { Home } from "../pages/Home/Home";
// import { Login } from "../pages/Login/Login";
import { ValidatingAccount } from "../pages/ValidatingAccount/ValidatingAccount";
import { ChangePassword } from "../pages/ChangePassword/ChangePassword";







export const ListOfRoutes =
    [
        // PUBLIC
        {
            path: "/*",
            Component: <ValidatingAccount />,
            authRequired: false
        }
        ,
        {
            path: "/validating-account/:token_access",
            Component: <ValidatingAccount />,
            authRequired: false
        },       
        {
            path: "/change-password/:codeRequestChange",
            Component: <ChangePassword />,
            authRequired: false
        },  
        /*
            {
                path: "/login",
                Component: <Login margin="auto"/>,
                authRequired: false
            },

            */

        /*
               // PRIVATE
                {
                    path: "/*",
                    Component: <Home />,
                    authRequired: true
                },
        */

    ]

import styled from "styled-components"


export const Texto = styled.span`
    color: ${props => props.color};
    font-size: ${props => props.letterFont};
    font-family: 'Roboto', sans-serif;
    font-weight: bold;
`
export const ContainerImg = styled.div`
text-align: center;
`
export const ContainerElements = styled.div`
    text-align: center;
    margin-top: 20px;
`
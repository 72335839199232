import React, { useEffect } from 'react'

import { useNavigate, useParams } from 'react-router-dom'

import { useMutation, useQuery, useQueryClient } from 'react-query'
import { validateCodeRequestChangePassword } from '../../../Api/peticiones_server'

import successActivation from '../../../images/icons/img/success-activation.png'
import errorActivation from '../../../images/icons/img/error-activation.png'
import { MessageItem } from '../ValidatingAccount/MessageItem'
import { Texto, ContainerImg, ContainerElements } from './ChangePasswordElement';// styleds
import { Header } from '../Header/Header'

import { error_MSG } from '../../../utilities/sweetAalerts/dialogs';
import { FormNewPassword } from './FormNewPassword'



export const ChangePassword = () => {

  const { codeRequestChange } = useParams();
  const navitage = useNavigate()

  // REACT QUERRY 1
  const queryClient = useQueryClient()

  // Get-autorization-code to change password
  const { data: dataRequestPassword, success: successDataRequestPassword,
    error: errorDataRequestPassword, isLoading: isLoadingDataRequestPassword } =
    useQuery('get_token_from_web', async () => {
      if (codeRequestChange) {
        const RequestCodeFormat = {
          "codeRequestChange": codeRequestChange
        };
        return validateCodeRequestChangePassword(RequestCodeFormat);
      } else { throw new Error('EL TOKEN NO EXISTE') }
    })


  // Get-Me
  // const { data: myData, success: successMyData, error: errorMyData, isLoading: isLoadingMyData } = useQuery('get_Me_With_Token', async () => {
  //   if (param_token_access) {
  //     const tokenOfActivation = {
  //       "token": param_token_access
  //     };
  //     return getMeWithToken(tokenOfActivation);
  //   } else { throw new Error('EL TOKEN NO EXISTE') }
  // })

  // useEffect(() => {
  //   console.log('ESTAS EN CHANGE');
  // }, [])

  if (isLoadingDataRequestPassword) {
    console.log('CARGANDOO ...')

    return <div style={{ textAlign: 'center', marginTop: '10%' }}>
      <h3> Validando codigo de seguridad</h3>
      <div className="spinner-border text-info" role="status">
        <span className="visually-hidden">Loading</span>
      </div>
    </div>
    // return <h1 className='btn-success'>Validando Usuario ...</h1>
  }

  if (errorDataRequestPassword) {
    // console.log('ERROR 5555 ...' + errorGetToken)
    error_MSG('No se ha encontrado un usuario con los datos suministrados');
  }

  return (

    <div>

      <Header logeado={true} />
      <ContainerElements>

        {

          codeRequestChange ?
            // EXISTE PARAMETRO
            // SUCCESS
            <>

              {
                dataRequestPassword.success ?
                  <div style={{ textAlign: 'center' }}>
                    <Texto color="black" letterFont="40px">
                      INTRODUZCA SU NUEVA CONTRASEÑA
                    </Texto>
                    <FormNewPassword margin="auto" codeToChange={dataRequestPassword.payload.codeToChange} />
                  </div>
                  :
                  <>
                    <div style={{ textAlign: 'center' }}>
                      <Texto color="black" letterFont="40px">
                        Error al validar los datos!
                      </Texto>
                      <ContainerImg>
                        <img src={errorActivation} width="150"></img>
                      </ContainerImg>
                    </div>
                  </>
              }
            </>

            // NO EXISTE PARAMETRO
            : <>
              <div style={{ textAlign: 'center' }}>
                <Texto color="black" letterFont="40px">
                  Error al validar los datos!
                </Texto>

                <ContainerImg>
                  <img src={errorActivation} width="150"></img>
                </ContainerImg>

              </div>
            </>}
      </ContainerElements>
    </div>

  )
}
